import styled from "styled-components/macro";
import { Box, Heading, Paragraph } from "~styled";

const Privacy = styled(Box)`
  background-color: #f3f4f6;
  padding-bottom: 20px;
  margin-top: 78px;

  @media (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .privacy-container {
    background-color: #f3f4f6;
  }
`;
Privacy.Title = styled(Heading)`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.4423;
  margin-bottom: 20px;

  @media (min-width: 576px) {
    font-size: 35px;
  }
  @media (min-width: 768px) {
    font-size: 42px;
  }
  @media (min-width: 992px) {
    font-size: 52px;
    margin-bottom: 42px;
  }
`;
Privacy.TitleSmall = styled(Heading)`
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.42857;
  margin-bottom: 15px;
  @media (min-width: 992px) {
    margin-bottom: 25px;
  }
`;
Privacy.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.777777;
  @media (min-width: 992px) {
    font-size: 18px;
  }
`;
Privacy.List = styled(Box)``;

Privacy.ListItem = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.777777;
  @media (min-width: 992px) {
    font-size: 18px;
  }
`;
Privacy.ContentBox = styled(Box)`
  padding-top: 10px;
  padding-bottom: 20px;
`;
Privacy.Content = styled(Box)`
  @media (min-width: 1200px) {
    padding: 0 43px;
  }
  @media (min-width: 1400px) {
    padding: 0 53px;
  }
`;

Privacy.Box = styled(Box)``;

export default Privacy;
