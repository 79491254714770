import LogoBlack from "~image/logo/logo-black.png";
import LogoWhite from "~image/logo/logo-white.png";
import herol4Img from "~image/home-services/hero-l4-image.jpg";
import herol4ImgOverlay from "~image/home-services/hero-overlay.png";
import aboutUsl4Img1 from "~image/home-services/about-us-image-l4-1.png";
import aboutUsl4Img2 from "~image/home-services/about-us-image-l4-2.png";
import l4whyChooseImg from "~image/home-services/content-img-l4-2.png";
import l4promoBg from "~image/home-services/promo-l4-img.png";
import l4MapImg from "~image/home-services/contact-map.png";
import errorImage from "~image/mixed/404.png";

const Images = {
  Services: {
    heroImg: herol4Img,
    heroImgOverlay: herol4ImgOverlay,
    aboutUsImg1: aboutUsl4Img1,
    aboutUsImg2: aboutUsl4Img2,
    whyChooseImg: l4whyChooseImg,
    promoBg: l4promoBg,
    mapImg: l4MapImg,
  },

  FooterOne: {
    LogoWhite: LogoWhite,
  },
  FooterFourDark: {
    LogoWhite: LogoWhite,
  },
  FooterSix: {
    LogoBlack: LogoBlack,
  },

  errorImage: errorImage,
};

export default Images;
