import { Link } from "~components";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "~data";
import Footer from "./style";
import { Trans, Link as I18nextLink } from "gatsby-plugin-react-i18next";
import siteLogo from "~image/job-explore-logo.png";

export default function FooterTwo() {
  return (
    <Footer>
      <Container>
        <Footer.Box pbXL="95px">
          <Row>
            <Col xs="6" className="col-lg-4 col-md-8 col-xs-6">
              <Footer.Widgets className="footer-widgets footer-widgets--l7">
                {/* Brand Logo*/}
                <Footer.Box mb="30px">
                  <Link to="#">
                    <img src={siteLogo} alt="logo" style={{ width: "200px" }} />
                  </Link>
                </Footer.Box>
                <Footer.Text mb="36px">
                  <Trans>
                    We are waiting for you at a consultation, find out about the
                    new job offers abroad!
                  </Trans>
                  {/* <br className="d-none d-xl-block" /> */}
                </Footer.Text>
              </Footer.Widgets>
            </Col>
            <Col xs="12" className="col-xl-8">
              <Row>
                <Col xs="12" className="col-md-4 col-xs-6">
                  <Footer.Widgets>
                    <Footer.Title>
                      <Trans>Company</Trans>
                    </Footer.Title>
                    <Footer.List>
                      <Footer.ListItems>
                        <a href="#our-offers">
                          <Trans>Our Offers</Trans>
                        </a>
                      </Footer.ListItems>
                      <Footer.ListItems>
                        <a href="#how-we-work">
                          <Trans>How We Work</Trans>
                        </a>
                      </Footer.ListItems>
                    </Footer.List>
                  </Footer.Widgets>
                </Col>
                <Col xs="12" className="col-md-4 col-xs-6">
                  <Footer.Widgets>
                    <Footer.Title>
                      <Trans>Contact Details</Trans>
                    </Footer.Title>
                    <Footer.Address className="widgets-address">
                      <Footer.AddressItem>
                        <i className="fa fa-map-marker-alt" />
                        <span>
                          <Trans>Chisinau</Trans> <br className="d-block" />
                          <Trans>31 Ismail street, of. 16</Trans>
                        </span>
                      </Footer.AddressItem>
                      <Footer.AddressItem>
                        <i className="fa fa-phone-alt" />
                        <a href="tel:+37362000050">+373 62 00 00 50</a>
                      </Footer.AddressItem>
                      <Footer.AddressItem>
                        <i className="fa fa-envelope" />
                        <a href="mailto:office@jobexplore.eu">
                          office@jobexplore.eu
                        </a>
                      </Footer.AddressItem>
                    </Footer.Address>
                  </Footer.Widgets>
                </Col>
                {/* <Col xs="12" className="col-md-4 col-xs-7">
                  <Footer.Widgets>
                    <Footer.Title>
                      <Trans>Follow us on social media</Trans>
                    </Footer.Title>
                    <Footer.SocialShare>
                      <Footer.Box pt="11px" pb="30px">
                        <Footer.SocialShareItem>
                          <a href="#">Facebook</a>
                        </Footer.SocialShareItem>
                        <Footer.SocialShareItem>
                          <a href="#">Twitter</a>
                        </Footer.SocialShareItem>
                        <Footer.SocialShareItem>
                          <a href="#">Instagram</a>
                        </Footer.SocialShareItem>
                      </Footer.Box>
                    </Footer.SocialShare>
                  </Footer.Widgets>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Footer.Box>
        <Footer.Copyright>
          <Footer.CopyrightText>
            © 2024 JobExplore.eu.&nbsp;
            <I18nextLink to="/privacy/" style={{ textDecoration: "underline" }}>
              <Trans>Privacy policy</Trans>
            </I18nextLink>
          </Footer.CopyrightText>
        </Footer.Copyright>
      </Container>
    </Footer>
  );
}
