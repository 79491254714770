import React from "react";
import { PageWrapper } from "~components/Core";
import PrivacyPolicySection from "~sections/services/PrivacyPolicy";
import FooterTwo from "~sections/services/FooterTwo";
import { graphql } from "gatsby";
import { Layout } from "~components/Core";

export default function Services() {
  const header = {
    headerClasses:
      "site-header site-header--menu-center light-header site-header--with-border site-header--sticky",
    containerFluid: false,
    darkLogo: true,
  };

  return (
    <Layout innerPage={true}>
      <PageWrapper headerConfig={header}>
        <PrivacyPolicySection />
        <FooterTwo />
      </PageWrapper>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index", "privacy"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
