import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Privacy from "./style";
import { Trans } from "gatsby-plugin-react-i18next";

export default function PrivacyPolicy() {
  return (
    <Privacy>
      <Container className="privacy-container">
        <Row className="justify-content-center">
          <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
            <Privacy.Box>
              <Privacy.Title as="h2">
                <Trans>Privacy and cookie policy</Trans>
              </Privacy.Title>
            </Privacy.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Privacy.Content>
              <Privacy.Text>
                <Trans>Privacy text1</Trans>
              </Privacy.Text>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title2</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text2</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title3</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text3</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title4</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text4</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title5</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text5</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title6</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text6</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title7</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text7</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title8</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text8</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title8</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text8</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title10</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text10</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title11</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text11</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title12</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text12</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title13</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text13</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title14</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text14</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
              <Privacy.ContentBox>
                <Privacy.TitleSmall as="h5">
                  <Trans>Privacy title15</Trans>
                </Privacy.TitleSmall>
                <Privacy.Text>
                  <Trans>Privacy text15</Trans>
                </Privacy.Text>
              </Privacy.ContentBox>
            </Privacy.Content>
          </Col>
        </Row>
      </Container>
    </Privacy>
  );
}
